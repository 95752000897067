import {GetStartedSteps} from 'components/pages/DashboardPage/components/GetStartedWidget/useGetStartedWidget';
import {EnableEmailTypes} from 'types/api';
import {DashboardLayoutState} from 'types/ui/dashboardLayoutState';
import {DeepPartial} from 'types/utils/utilitytypes';
import {StepTypes} from 'types/onboarding';
import {Currency} from '../currencies';

export interface IReviewProcessStep {
    date: string;
    timezone: string;
    timezone_type: string;
}

export interface IBusinessPartnerReviewProcess {
    '@id': string;
    '@type': 'ReviewProcess';
    generateOnboardingContract: boolean;
    pendingStart: string;
    id: number;
    steps: Record<string, IReviewProcessStep>;
    status: 'pending' | 'ready_for_compliance' | 'compliance_approved';
}

export interface IUpdateBusinessPartnerOnboarding
    extends DeepPartial<BusinessPartner> {
    gwgInfo?: DeepPartial<BusinessPartner['gwgInfo']> &
        Pick<BusinessPartner['gwgInfo'], 'id' | 'incorporationNumber'> & {
            relevantCurrencies?: string[];
        };
    step: StepTypes;
    businessPartnerId: string;
}

export type BusinessPartnerStatus =
    | 'closed'
    | 'onboarding'
    | 'yet to trade'
    | 'active'
    | 'ready for compliance'
    | 'contract generated'
    | 'in_compliance_review'
    | 'rejected'
    | 'in_sales_review'
    | 'dormant';

export const REASON_FOR_AMNIS = [
    'fx',
    'incoming_payment',
    'outgoing_payment',
    'debit_card',
] as const;
export type ReasonForAmnis = typeof REASON_FOR_AMNIS[number];

export type PaymentCustomer = {
    name: string;
    country: string;
    uniqueId: string;
    website?: string;
    percentage?: string | number;
};

export type GwgInfo = {
    '@id': string;
    '@type': 'GwgInfo';
    id: string;
    incorporationNumber?: string;
    sourceOfFunds?: string;
    returnBudget?: string;
    businessIndustry?: string;
    numberEmployees?: string;
    operationalBusiness?: boolean;
    exchangeReason?: string[];
    formOfControl?: number;
    assetsOwner?: boolean;
    currencyVolumeLower?: string;
    currencyVolumeUpper?: string;
    customerBusinessDescription?: string;
    mainSuppliers?: string;
    mainCustomers?: string;
    countryOfBusiness?: string[];
    relevantCurrencies?: string[] | null;
    referralCode?: string;
    isGroup?: boolean;
    reasonForAmnis?: ReasonForAmnis[] | null;
    businessActivities: string[];
    physicalPresence: string[];
    paymentSize: string;
    paymentTypes: string[];
    paymentVolume: string;
    incomingPaymentCountries: string[];
    incomingPaymentCustomers: PaymentCustomer[];
    outgoingPaymentCountries: string[];
    outgoingPaymentCustomers: PaymentCustomer[];
    informationCollected: boolean;
    cardPurpose: string[];
    cardVolume: string;
    cardCount: string;
    pep: boolean | null;
    pepDescription: string | null;
    customerRelationIncomingFlow?: string[] | null;
    generateIncomingFlow?: string[] | null;
    extendedSoWQuestions?: boolean | null;
    ownerOfCompany?: boolean | null;
    isCompanyOnAmnis?: boolean | null;
    investedAmount?: string;
    ownerSourceOfFund?: string;
    privateInvestmentSource?: string[];
    website?: string;
};

export type BusinessPartner = {
    '@id': string;
    '@type': string;
    amnisBranchCountry: string;
    amnisLegalEntityCountry: string;
    amountLimit: number;
    canSwap: boolean;
    canForward: boolean;
    cardBillingCurrency: string;
    cardsBlocked: boolean;
    companyName: string;
    cardCompanyName: string;
    currencies: Currency[];
    currencyCloudEnabled: boolean;
    currencyVolume: string;
    forwardDaysLimit: number;
    id: number;
    identified: boolean;
    isCapitalCostOff: boolean;
    paymentBlocked: boolean;
    permission?: Permission; // if eg. ROLE_SALES permission might be null
    referralCode: string;
    referralType: string;
    sandbox: boolean;
    contacts: BusinessPartnerContacts[];
    availableBalance: number;
    own_contact?: {
        '@id': string;
        bankAccounts?: any[];
    };
    address?: string;
    city?: string;
    zip?: string;
    stateOrProvince?: string;
    countryOfIncorporation?: string;
    legalForm?: string;
    ebillSubscription?: {
        enabled: boolean;
    };
    gwgInfo?: GwgInfo;
    website?: string;
    mcaSigned?: boolean;
    bexio: boolean;
    fileUploadEnabled: boolean;
    mainCurrency: string;
    mostTradedCurrencyPair: string;
    mostTradedCurrencyPairForChart: string;
    WIRCustomerId?: string;
    activePackage: number;
    packageChangedAt: null | string;
    discountName: null | string;
    relationshipManagerId: null | string;
    dateOfIncorporation?: string;
    pricingModel: number;
    reviewProcess: IBusinessPartnerReviewProcess | null;
    status: BusinessPartnerStatus;
    allCardsSuspended: boolean;
    cardTermsAccepted?: boolean;
    referenceCurrency: string;
    payInCardCurrency?: boolean;
    fourEyeOwnPaymentSignRequired: boolean;
};

export type BusinessPartnerListItem = Pick<
    BusinessPartner,
    'id' | 'companyName' | 'sandbox' | '@id' | '@type' | 'identified'
> & {alreadyPeer?: boolean};
export type BusinessPartnerList = BusinessPartnerListItem[];

export type PermissionSettings = Record<
    Exclude<GetStartedSteps, 'onboarding'>,
    boolean
>;
export type Permission = {
    '@id': string;
    admin: boolean;
    allowedToSign: boolean;
    createContact: boolean;
    fourEyeSignRequired: boolean;
    fxTrade: boolean;
    limitTrade: boolean;
    swapTrade: boolean;
    enableEmailTypes: EnableEmailTypes;
    dashboardLayout: DashboardLayoutState;
    settings: PermissionSettings;
    onlyCardUser: boolean;
    cardAdmin: boolean;
};

// TODO - unknowns
export type BusinessPartnerContacts = {
    '@id': string;
    '@type': string;
    address: string;
    businessPartner: string;
    city: string;
    connectedBusinessPartner: null | string;
    contactInvites: unknown[];
    country: string;
    name: string;
    selfContact: boolean;
    trackingEmail: null | string;
    type: string;
    zip: string;
};

export type BusinessActivity = {
    '@id': string;
    '@type': 'BusinessActivity';
    id: number;
    value: string;
};

export type BusinessPartnerPaymentTypes = {
    '@id': string;
    '@type': 'PaymentType';
    id: number;
    value: string;
    company: boolean | null;
};

export type BusinessPartnerCardPurpose = {
    '@id': string;
    '@type': 'CardUsagePurpose';
    id: number;
    value: string;
};

type CompanyGroupDocument = {
    category: 'company_group';
};

type IncomingPaymentCustomerDocument = {
    category: 'incoming_payment_customer';
    uniqueId: string;
};

type OutgoingPaymentCustomerDocument = {
    category: 'outgoing_payment_customer';
    uniqueId: string;
};

type InvestmentEvidenceDocument = {
    category: 'edd_evidence_inheritance';
};

type EmploymentEvidenceDocument = {
    category: 'edd_evidence_employment';
};

type IdDocument = {
    category: 'id_document';
    crp: number;
};

type UtilityBillDocument = {
    category: 'utility_bill';
    crp: number;
};

type ProofOfOwnership = {
    category: 'proof_of_ownership';
};

type InvestmentEvidence = {
    category: 'investment_evidence';
};

type PrivateSourceOfFunds = {
    category: 'private_source_of_funds';
};

export type BusinessPartnerRequiredDocument =
    | CompanyGroupDocument
    | IncomingPaymentCustomerDocument
    | OutgoingPaymentCustomerDocument
    | InvestmentEvidenceDocument
    | IdDocument
    | UtilityBillDocument
    | EmploymentEvidenceDocument
    | ProofOfOwnership
    | InvestmentEvidence
    | PrivateSourceOfFunds;
