import {ReasonForAmnis} from 'types/api';
import {LegalType} from 'types/onboarding';

export enum CrpVariants {
    OWNER = 'owner',
    SIGNER = 'signer',
    CONTRACT_SIGNER = 'contract_signer',
    ADMIN = 'admin',
}

export const currencyVolumeData = (legalType: LegalType) => {
    if (legalType === null) return [];

    switch (legalType) {
        case LegalType.COMPANY:
            return [
                {
                    label: '<500K',
                    '@id': '0-500000',
                },
                {
                    label: '500K-1.5M',
                    '@id': '500000-1500000',
                },
                {
                    label: '1.5M - 5M',
                    '@id': '1500000-5000000',
                },
                {
                    label: '5M-10M',
                    '@id': '5000000-10000000',
                },
                {
                    label: '>10M',
                    '@id': '10000000-10000000',
                },
            ];
        case LegalType.INDIVIDUAL: {
            return [
                {
                    label: '<25K',
                    '@id': '0-25000',
                },
                {
                    label: '25K-100K',
                    '@id': '25000-100000',
                },
                {
                    label: '100K-250K',
                    '@id': '100000-250000',
                },
                {
                    label: '250K-500K',
                    '@id': '250000-500000',
                },
                {
                    label: '>500K',
                    '@id': '500000-500000',
                },
            ];
        }
        default:
            throw new Error(`Invalid LegalType: ${legalType}`);
    }
};

export const BUDGET_LIST = [
    {
        label: '<1M',
        '@id': '0',
    },
    {
        label: '1M-2.5M',
        '@id': '1',
    },
    {
        label: '2.5M-5M',
        '@id': '2.5',
    },
    {
        label: '5M-10M',
        '@id': '5',
    },
    {
        label: '>10M',
        '@id': '10',
    },
];

export const transactionsData = [
    {label: 'less than 5 transactions', '@id': '0-5'},
    {label: '6 to 20 transactions', '@id': '6-20'},
    {label: '21 to 50 transactions', '@id': '21-50'},
    {label: 'more than 50 transaction', '@id': '50'},
];

export const exchangeReasonData = [
    {label: 'Import', name: 'Import', value: 'Import'},
    {label: 'Export', name: 'Export', value: 'Export'},
    {label: 'Investment', name: 'Investment', value: 'Investment'},
    {label: 'Other', name: 'Other', value: 'Other'},
];

export const CRP_ROLE_MAPPING: Record<CrpVariants, string> = {
    [CrpVariants.OWNER]: 'Beneficial owner',
    [CrpVariants.SIGNER]: 'Authorized signatory',
    [CrpVariants.CONTRACT_SIGNER]: 'Contract signer',
    [CrpVariants.ADMIN]: 'Administrator',
};

export const physicalPresenceData = [
    {label: 'Offices', '@id': 'Offices'},
    {label: 'Warehouses', '@id': 'Warehouses'},
    {label: 'Stores', '@id': 'Stores'},
    {label: 'Web-Presence', '@id': 'Web-Presence'},
    {label: 'Production plant', '@id': 'Production plant'},
];

export const PAYMENT_VOLUME: Record<
    LegalType,
    {label: string; '@id': string}[]
> = {
    [LegalType.COMPANY]: [
        {label: '0-0.5M', '@id': '0-0.5'},
        {label: '0.5-1M', '@id': '0.5-1'},
        {label: '1-2M', '@id': '1-2'},
        {label: '2-3M', '@id': '2-3'},
        {label: '3-4M', '@id': '3-4'},
        {label: '4-5M', '@id': '4-5'},
        {label: '5-10M', '@id': '5-10'},
        {label: '>10M', '@id': '10-'},
    ],
    [LegalType.INDIVIDUAL]: [
        {label: '<25K', '@id': '0-0.025'},
        {label: '0.025-0.1M', '@id': '0.025-0.1'},
        {label: '0.1-0.25M', '@id': '0.1-0.25'},
        {label: '0.25-0.5M', '@id': '0.25-0.5'},
        {label: '>0.5M', '@id': '0.5-'},
    ],
};

export const REASON_FOR_AMNIS_LABEL_MAPPING: Record<ReasonForAmnis, string> = {
    debit_card: 'Debit card',
    fx: 'Currency exchange',
    incoming_payment: 'Incoming payments',
    outgoing_payment: 'Outgoing payments',
};

export const PAYMENT_SIZES = [
    {label: '0-10K', '@id': '0-10'},
    {label: '10-25K', '@id': '10-25'},
    {label: '25-50K', '@id': '25-50'},
    {label: '50-100K', '@id': '50-100'},
    {label: '>100K', '@id': '100-'},
];

export const CARD_VOLUME = [
    {label: '<25K', '@id': '0-25'},
    {label: '25K-50K', '@id': '25-50'},
    {label: '50K-100K', '@id': '50-100'},
    {label: '>100K', '@id': '100-'},
];

export const CUSTOMER_RELATION_INCOMING_FLOW_OPTIONS = [
    {label: 'B2B (business-to-business)', '@id': 'business_to_business'},
    {label: 'B2C (business-to-consumer)', '@id': 'business_to_consumer'},
    {label: 'C2C (consumer-to-consumer)', '@id': 'consumer_to_consumer'},
    {label: 'C2B (consumer-to-business)', '@id': 'consumer_to_business'},
];

export const GENERATE_INCOMING_FLOW_OPTIONS = [
    {label: 'Sale of own products', '@id': 'sale_own_product'},
    {label: 'Sale of own services', '@id': 'sale_own_service'},
    {
        label: 'Sale of all kind of products',
        '@id': 'sale_multiple_type_product',
    },
    {label: 'Investments', '@id': 'investment'},
];

export const OWNER_SOURCE_OF_FUNDS = [
    {'@id': 'inheritance', label: 'Inheritance'},
    {'@id': 'employment', label: 'Employment'},
    {'@id': 'private_investment_gain', label: 'Private investment gain'},
    {'@id': 'thirdparty_investment', label: 'Third-party investment'},
];

export const INVESTMENT_OPTIONS = [
    {'@id': 'private_equity', label: 'Private equity/venture investments'},
    {'@id': 'financial_investments', label: 'Financial Investments'},
    {'@id': 'real_estate', label: 'Real Estate'},
    {'@id': 'commodities', label: 'Commodities'},
    {'@id': 'cryptocurrencies', label: 'Cryptocurrencies'},
    {'@id': 'other', label: 'Other'},
];

export const INVESTMENT_AMOUNTS = [
    {'@id': '0-100K', label: '<100K'},
    {'@id': '0.1-0.5M', label: '0.1-0.5M'},
    {'@id': '0.5-1M', label: '0.5-1M'},
    {'@id': '1.0-1.5M', label: '1.0-1.5M'},
    {'@id': '1.5-2M', label: '1.5-2M'},
    {'@id': '2M-', label: '>2M'},
];
