import React from 'react';
import classNames from 'classnames';
import {Alert, Button, Col, Modal, Row, Table} from 'react-bootstrap';
import {ActiveSwapDeal} from 'types/order';
import {useCountdown} from 'hooks/common';
import {SWAP_DEAL_TIMEOUT} from 'constants/order';
import {formatDateInZurichTZ} from 'helpers/dates';
import SubmitButton from 'components/ui/Button/SubmitButton';
import {formatNumber} from 'helpers/common';
import CurrencyFlag from 'components/ui/CurrencyFlag/CurrencyFlag';
import styles from './SwapOrderConfirmationModal.module.scss';

interface IProps {
    activeSwapDeal: ActiveSwapDeal;
    isLoading: boolean;
    handleConfirm(): void;
    handleCancel(): void;
    handleRefresh(callback: () => void): void;
}

const SwapOrderConfirmationModal: React.FC<IProps> = ({
    activeSwapDeal: {nearDealRequest, farDealRequest},
    isLoading,
    handleCancel,
    handleConfirm,
    handleRefresh,
}) => {
    const {restartCounter, stopCounter, timeLeft} = useCountdown(
        SWAP_DEAL_TIMEOUT,
    );

    const onRefreshClick = () => {
        stopCounter();
        handleRefresh(() => {
            restartCounter();
        });
    };

    return (
        <>
            <Modal.Header closeButton={false}>
                <Modal.Title as="h5">Anfrage Devisen-Swap</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <Alert variant="warning">
                            Diese Offerte ist zwei Minuten gültig. Bitte
                            bestätigen Sie die Anpassung.
                        </Alert>
                    </Col>
                    <Col md={12}>
                        <Table className={styles.table} borderless responsive>
                            <thead>
                                <tr>
                                    <th />
                                    <th colSpan={2}>Near Date</th>
                                    <th />
                                    <th colSpan={2}>Far Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Value date</td>
                                    <td colSpan={2} data-notranslate>
                                        {formatDateInZurichTZ(
                                            nearDealRequest.valueDate,
                                        )}
                                    </td>
                                    <td />
                                    <td colSpan={2} data-notranslate>
                                        {formatDateInZurichTZ(
                                            farDealRequest.valueDate,
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mid rate</td>
                                    <td
                                        className={styles.highlighted}
                                        colSpan={2}
                                        data-notranslate
                                    >
                                        {nearDealRequest.usedRate}
                                    </td>
                                    <td />
                                    <td
                                        className={styles.highlighted}
                                        colSpan={2}
                                        data-notranslate
                                    >
                                        {farDealRequest.usedRate}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Fee</td>
                                    <td
                                        className={styles.highlighted}
                                        colSpan={2}
                                        data-notranslate
                                    >
                                        {nearDealRequest.fee}
                                    </td>
                                    <td />
                                    <td
                                        className={styles.highlighted}
                                        colSpan={2}
                                        data-notranslate
                                    >
                                        {farDealRequest.fee}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Forward rate</td>
                                    <td
                                        className={styles.highlighted}
                                        colSpan={2}
                                        data-notranslate
                                    >
                                        {formatNumber(
                                            nearDealRequest.forwardPips,
                                            4,
                                        )}
                                    </td>
                                    <td />
                                    <td
                                        className={styles.highlighted}
                                        colSpan={2}
                                        data-notranslate
                                    >
                                        {formatNumber(
                                            farDealRequest.forwardPips,
                                            4,
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Exchange rate</td>
                                    <td
                                        className={styles.highlighted}
                                        colSpan={2}
                                        data-notranslate
                                    >
                                        {formatNumber(
                                            nearDealRequest.platformRate,
                                            4,
                                        )}
                                    </td>
                                    <td />
                                    <td
                                        className={styles.highlighted}
                                        colSpan={2}
                                        data-notranslate
                                    >
                                        {formatNumber(
                                            farDealRequest.platformRate,
                                            4,
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>You buy</td>
                                    <td
                                        className={classNames(
                                            styles.highlighted,
                                            styles.bold,
                                        )}
                                    >
                                        <CurrencyFlag
                                            code={nearDealRequest.currencyTo
                                                .split('/')
                                                .pop()}
                                        />
                                    </td>
                                    <td
                                        className={classNames(
                                            styles.highlighted,
                                            styles.bold,
                                        )}
                                        data-notranslate
                                    >
                                        {formatNumber(nearDealRequest.amountTo)}
                                    </td>
                                    <td />
                                    <td
                                        className={classNames(
                                            styles.highlighted,
                                            styles.bold,
                                        )}
                                    >
                                        <CurrencyFlag
                                            code={farDealRequest.currencyTo
                                                .split('/')
                                                .pop()}
                                        />
                                    </td>
                                    <td
                                        className={classNames(
                                            styles.highlighted,
                                            styles.bold,
                                        )}
                                        data-notranslate
                                    >
                                        {formatNumber(farDealRequest.amountTo)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>You sell</td>
                                    <td
                                        className={classNames(
                                            styles.highlighted,
                                            styles.bold,
                                        )}
                                    >
                                        <CurrencyFlag
                                            code={nearDealRequest.currencyFrom
                                                .split('/')
                                                .pop()}
                                        />
                                    </td>
                                    <td
                                        className={classNames(
                                            styles.highlighted,
                                            styles.bold,
                                        )}
                                        data-notranslate
                                    >
                                        {formatNumber(
                                            nearDealRequest.amountFrom,
                                        )}
                                    </td>
                                    <td />
                                    <td
                                        className={classNames(
                                            styles.highlighted,
                                            styles.bold,
                                        )}
                                    >
                                        <CurrencyFlag
                                            code={farDealRequest.currencyFrom
                                                .split('/')
                                                .pop()}
                                        />
                                    </td>
                                    <td
                                        className={classNames(
                                            styles.highlighted,
                                            styles.bold,
                                        )}
                                        data-notranslate
                                    >
                                        {formatNumber(
                                            farDealRequest.amountFrom,
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    {timeLeft ? (
                        <div className={styles.timeLeft}>
                            Your offer is valid for{' '}
                            <var data-var="time-left">
                                <b>{timeLeft}</b>
                            </var>
                        </div>
                    ) : (
                        false
                    )}
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={handleCancel}>
                    Abbrechen
                </Button>
                <SubmitButton
                    onClick={onRefreshClick}
                    isLoading={isLoading}
                    disabled={!!timeLeft}
                >
                    Refresh
                </SubmitButton>

                <SubmitButton
                    onClick={handleConfirm}
                    isLoading={isLoading}
                    disabled={!timeLeft}
                >
                    Confirm
                </SubmitButton>
            </Modal.Footer>
        </>
    );
};

export default SwapOrderConfirmationModal;
