import {
    API_DATE_FORMAT,
    DISPLAY_DATE_FORMAT,
    DISPLAY_DATETIME_FORMAT,
    TIME_FORMAT,
    ZURICH_TIMEZONE,
} from 'constants/date';
import {ICurrency} from 'api/currencies';

import {
    getDay,
    addBusinessDays,
    format,
    isBefore,
    parseISO,
    set,
    parse,
    isWeekend,
    isSameDay,
    getMonth,
    getDate,
    addDays,
} from 'date-fns';

// exclude weekends from calendar
export const isWeekDay = (date: Date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
};

/**
 * Return the current date like YYYY.mm.dd
 * */
export const getToday = () => new Date().toISOString().split('T')[0];

/**
 * Formats the date in the passed in format (default FORMAT_DISPLAY_DATE), without forced conversion to Zurich timezone
 *
 * @param date Date object or date string
 * @param dateFormat optional formatting (see date-fns format options)
 * @param locale optional locale (see date-fns Locale)
 * @returns formatted date string
 */
export const formatDateWithoutTZConversion = (
    date: string | Date,
    dateFormat?: string,
    locale?: Locale,
) => {
    if (!date) return '';
    let dateStr: string;
    if (date instanceof Date) {
        dateStr = format(date, API_DATE_FORMAT);
    } else {
        dateStr = date;
    }
    const temp = new Date(
        dateStr.includes('T') ? dateStr.split('T')[0] : dateStr,
    );
    const newDate = new Date(
        temp.getTime() + temp.getTimezoneOffset() * 60 * 1000,
    );
    return format(newDate, dateFormat || DISPLAY_DATE_FORMAT, {locale});
};

/**
 * Formats the date in the passed-in format (default FORMAT_DISPLAY_DATE) AND in Zurich timezone
 *
 * @param date Date object or date string
 * @param dateFormat optional formatting (see date-fns format options)
 * @param locale optional locale (see date-fns Locale)
 * @returns formatted date string
 */
export const formatDateInZurichTZ = (
    date: string | Date,
    dateFormat?: string,
    locale?: Locale,
) => {
    if (!date) return '';
    let dateStr: string;
    if (date instanceof Date) {
        dateStr = format(
            new Date(
                date.toLocaleDateString('en-US', {timeZone: ZURICH_TIMEZONE}),
            ),
            API_DATE_FORMAT,
        );
    } else {
        dateStr = date;
    }
    const temp = new Date(
        dateStr.includes('T') ? dateStr.split('T')[0] : dateStr,
    );
    const newDate = new Date(
        temp.getTime() + temp.getTimezoneOffset() * 60 * 1000,
    );
    return `${format(newDate, dateFormat || DISPLAY_DATE_FORMAT, {locale})}`;
};

export const formatBirthDate = (date: string) => {
    if (!date) return '';

    if (date && date.length === DISPLAY_DATE_FORMAT.length) {
        const parsedDate = parse(date, DISPLAY_DATE_FORMAT, new Date());
        return formatDateInZurichTZ(new Date(parsedDate));
    }

    return formatDateInZurichTZ(date);
};

export const formatTime = (date: string) => format(parseISO(date), TIME_FORMAT);
export const formatDateTime = (date: Date | string) => {
    const dateTime = new Date(
        new Date(date).toLocaleString('en-US', {timeZone: ZURICH_TIMEZONE}),
    );
    try {
        return format(dateTime, DISPLAY_DATETIME_FORMAT);
    } catch (err) {
        return `Invalid date: ${dateTime}`;
    }
};

export const isItBeforeTwoPmInZurich = (localeNowInUTC: Date) => {
    const zurichTwoPmInUTC = new Date(
        set(new Date(), {
            hours: 14,
            minutes: 0,
            seconds: 0,
        }),
    );

    return isBefore(localeNowInUTC, zurichTwoPmInUTC);
};

export const getNextBusinessDay = (date: Date, valueDay: number) => {
    return addBusinessDays(date, valueDay);
};

export const getDateInZurichTZ = (date: Date) => {
    const zurichNow = new Date(
        date.toLocaleString('en-US', {timeZone: ZURICH_TIMEZONE}),
    );

    return zurichNow;
};

export const getZurichNow = () => {
    return getDateInZurichTZ(new Date());
};

export const getLocalNowInZurich = () => {
    const userDate = new Date(); // the user's date
    return new Date(
        userDate.toLocaleString('en-US', {
            timeZone: ZURICH_TIMEZONE,
        }),
    ); // Convert user's local time to Zurich time
};

// Check nearest available day for transactions
export const getMinDateForCurrencies = (
    currencyList: ICurrency[],
    currencies: string[],
    holidaysDates: Date[],
    allDay = false,
    considerValueDay = false,
): Date => {
    const zurichDate = getLocalNowInZurich();

    function isTimeAllowed(date: string) {
        const time = format(parseISO(date), TIME_FORMAT).split(':');

        const closingTimeInZurich = new Date(
            new Date(
                set(zurichDate, {
                    hours: parseInt(time[0], 10),
                    minutes: parseInt(time[1], 10),
                    seconds: 0,
                }),
            ).toLocaleString('en-US', {
                timeZone: ZURICH_TIMEZONE,
            }),
        );

        return isBefore(zurichDate, closingTimeInZurich);
    }

    function isTodayAllowed(currency: ICurrency) {
        if (
            (considerValueDay && currency.valueDay > 0) ||
            (!allDay &&
                (!isTimeAllowed(currency.closingTime) || isWeekend(zurichDate)))
        ) {
            return false;
        }

        return true;
    }

    let nearestPossible = zurichDate;

    currencies.forEach(curr => {
        const currency = currencyList.find(c => c['@id'] === curr);
        if (currency && !isTodayAllowed(currency)) {
            const next = getNextBusinessDay(
                zurichDate,
                Math.max(1, currency.valueDay),
            );
            if (isBefore(nearestPossible, next)) {
                nearestPossible = next;
            }
        }
    });

    if (holidaysDates.some(d => isSameDay(d, nearestPossible))) {
        nearestPossible = getNextPossibleDay(nearestPossible, holidaysDates);
    }

    return nearestPossible;
};

export const getDefaultDateForFX = (minDate: Date) => {
    const localNowInZurich = getLocalNowInZurich();

    if (!isSameDay(minDate, localNowInZurich)) {
        return minDate;
    }

    if (isItBeforeTwoPmInZurich(localNowInZurich)) {
        return localNowInZurich;
    }

    return getNextBusinessDay(localNowInZurich, 1);
};

export const getNextPossibleDay = (
    fromDate: Date,
    excludedDates: Date[],
    filterDates?: (date: Date) => boolean,
) => {
    let nextPossible = fromDate;
    let searching = true;
    while (searching) {
        const current = addDays(nextPossible, 1);
        nextPossible = current;
        if (
            !excludedDates?.some(d => isSameDay(d, current)) &&
            (filterDates === undefined || filterDates(current))
        ) {
            searching = false;
        }
    }

    return nextPossible;
};

export const getMaxDateByOffset = (
    minDate: Date,
    holidays: Date[],
    offset: number,
): Date => {
    let daysCounted = offset;
    let maxDate = new Date(minDate);
    while (daysCounted > 0) {
        const newMax = addBusinessDays(maxDate, 1);
        maxDate = newMax;
        if (!holidays.some(d => isSameDay(d, newMax))) {
            daysCounted -= 1;
        }
    }
    return maxDate;
};

export const parseDisplayDate = (dateString: string) =>
    parse(dateString, DISPLAY_DATE_FORMAT, new Date());

export const filterFxDates = (date: Date): boolean => {
    // december 25, january 1
    const fixHolidays = ['11.25.', '0.1.'];

    if (fixHolidays.includes(`${getMonth(date)}.${getDate(date)}.`)) {
        return false;
    }

    return isWeekDay(date) || isSameDay(date, new Date());
};
