import React, {useCallback, useMemo} from 'react';
import classNames from 'classnames';
import {
    Alert,
    Button,
    Col,
    FormControl,
    FormGroup,
    FormLabel,
    Modal,
    ModalBody,
    Row,
} from 'react-bootstrap';
import FilteredDatePicker from 'components/ui/FilteredDatePicker/FilteredDatePicker';
import {LimitOrderConfirmation} from 'types/order';
import {formatDateInZurichTZ, formatTime} from 'helpers/dates';
import {useCountdown} from 'hooks/common';
import {LIMIT_DEAL_TIMEOUT} from 'constants/order';
import SubmitButton from 'components/ui/Button/SubmitButton';
import {formatNumber} from 'helpers/common';
import CurrencyFlag from 'components/ui/CurrencyFlag/CurrencyFlag';
import {FIELD_BUY_AMOUNT, FIELD_SELL_AMOUNT} from 'constants/currencies';
import styles from './LimitOrderConfirmationModal.module.scss';

interface IProps {
    limitOrder: LimitOrderConfirmation;
    error: string;
    isLoading: boolean;
    onConfirm: (expireOn: string) => void;
    onRefresh: (limit: number, callback: () => void) => void;
    onClose: () => void;
    requested: string;
}

const LimitOrderConfirmationModal: React.FC<IProps> = ({
    error,
    limitOrder,
    isLoading,
    onConfirm,
    onClose,
    onRefresh,
    requested,
}) => {
    const {
        expireOn,
        dealRequest: {
            currencyFrom,
            currencyTo,
            amountTo,
            amountFrom,
            usedRate,
            platformRate,
            xigniteMidRate,
        },
    } = limitOrder;
    const [rateToCompare, setRateToCompare] = React.useState(Number(usedRate));
    const [limitRateNum, setLimitRateNum] = React.useState(Number(usedRate));
    const [limitRateText, setLimitRateText] = React.useState(
        formatNumber(usedRate, 4),
    );
    const [expiryDate, setExpiryDate] = React.useState(
        formatDateInZurichTZ(expireOn),
    );
    const [expiryTime, setExpiryTime] = React.useState(formatTime(expireOn));
    const {restartCounter, stopCounter, timeLeft} = useCountdown(
        LIMIT_DEAL_TIMEOUT,
    );

    const diffInPercent = (
        ((Number.parseFloat(usedRate) - Number.parseFloat(xigniteMidRate)) /
            Number.parseFloat(xigniteMidRate)) *
        100
    ).toFixed(4);

    const onChangeLimitRate = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const rate = Number(e.target.value);
            if (!Number.isNaN(rate)) {
                setLimitRateNum(rate);
                setLimitRateText(e.target.value);
            }
        },
        [],
    );

    const onChangeExpiryTime = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setExpiryTime(e.target.value);
        },
        [],
    );

    const onChangeExpiryDate = useCallback(date => {
        setExpiryDate(date);
    }, []);

    const handleConfirm = () => {
        onConfirm(`${expiryDate}T${expiryTime}`);
    };

    const handleRefresh = () => {
        // save previous rate for compare
        setRateToCompare(limitRateNum);

        stopCounter();
        onRefresh(limitRateNum, restartCounter);
    };

    const renderAlert = () => {
        const diff = Math.abs(Number.parseFloat(diffInPercent));

        if (diff < 0.1) {
            return (
                <Alert variant="warning" className="text-center">
                    The selected value is near to the market!
                </Alert>
            );
        }

        if (diff > 50) {
            return (
                <Alert variant="danger" className="text-center">
                    Your target rates deviate more then 50% from the market
                    price!
                </Alert>
            );
        }

        if (diff > 10) {
            return (
                <Alert variant="warning" className="text-center">
                    Your target rates deviate more than 10% from the market
                    price!
                </Alert>
            );
        }

        return null;
    };

    const currFrom = useMemo(() => currencyFrom.split('/').pop(), [
        currencyFrom,
    ]);
    const currTo = useMemo(() => currencyTo.split('/').pop(), [currencyTo]);
    const marketRateNumber = Number.parseFloat(xigniteMidRate).toFixed(4);
    const isDiffRed = Number.parseFloat(diffInPercent) > 10;
    const isNeedRefresh = !(limitRateNum === rateToCompare) || !timeLeft;

    return (
        <>
            <Modal.Header closeButton={false}>
                <Modal.Title as="h5">Enter Limit-Order</Modal.Title>
            </Modal.Header>
            <ModalBody>
                {renderAlert()}
                <Row className={styles.rowData}>
                    <Col md={3}>Order type</Col>
                    <Col md={4}>
                        <b>Limit order</b>
                    </Col>
                    <Col md={5}>
                        <small>
                            The order will be executed as soon as the market
                            touches your limit rate.
                        </small>
                    </Col>
                </Row>
                <Row className={classNames(styles.rowData, styles.highlighted)}>
                    <Col md={3}>
                        <div>Limit rate</div>
                        <small>interbank rate</small>
                    </Col>
                    <Col md={4}>
                        <FormControl
                            type="number"
                            step="0.0001"
                            value={limitRateText}
                            onChange={onChangeLimitRate}
                            onBlur={handleRefresh}
                        />
                    </Col>
                    <Col md={5}>
                        <small>
                            <span>
                                Market rate:{' '}
                                <span data-notranslate>
                                    {currFrom} / {currTo} {marketRateNumber}
                                </span>
                            </span>
                            <span className={styles.diffBlock}>
                                <span>Difference: </span>
                                <span
                                    className={classNames(styles.diff, {
                                        [styles.red]: isDiffRed,
                                    })}
                                    data-notranslate
                                >
                                    {diffInPercent} %
                                </span>
                            </span>
                        </small>
                    </Col>
                </Row>
                <Row className={styles.rowData}>
                    <Col md={3}>You buy</Col>
                    <Col md={4}>
                        <b>
                            <CurrencyFlag code={currTo} />{' '}
                            {requested === FIELD_BUY_AMOUNT ? (
                                <span data-notranslate>
                                    {formatNumber(amountTo)}
                                </span>
                            ) : null}
                        </b>
                    </Col>
                </Row>
                <Row className={styles.rowData}>
                    <Col md={3}>You sell</Col>
                    <Col md={4}>
                        <b>
                            <CurrencyFlag code={currFrom} />{' '}
                            {requested === FIELD_SELL_AMOUNT ? (
                                <span data-notranslate>
                                    {formatNumber(amountFrom)}
                                </span>
                            ) : null}
                        </b>
                    </Col>
                </Row>
                <Row className={styles.rowData}>
                    <Col md={3}>Expiry date</Col>
                    <FormGroup as={Col} md={4}>
                        <FormLabel className={styles.small}>
                            Expiry date
                        </FormLabel>
                        <FilteredDatePicker
                            currencyFrom={currencyFrom}
                            currencyTo={currencyTo}
                            value={expiryDate}
                            onChange={onChangeExpiryDate}
                        />
                    </FormGroup>
                    <FormGroup as={Col} md={4}>
                        <FormLabel className={styles.small}>
                            Expiry time (i.E. 12:00)
                        </FormLabel>
                        <FormControl
                            type="time"
                            value={expiryTime}
                            onChange={onChangeExpiryTime}
                        />
                    </FormGroup>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className={styles.text}>
                            The order is executed at interbank rate. The usual
                            margin will be charged additionally.
                        </div>
                    </Col>
                    <Col md={12}>
                        <Alert variant="info" className="text-center">
                            Expected execution: Buy{' '}
                            <var data-var="buy-amount-currency">
                                <span className="pl-1">{`${currTo
                                    .split('/')
                                    .pop()
                                    .toUpperCase()} ${formatNumber(
                                    amountTo,
                                )}`}</span>
                            </var>{' '}
                            /{' '}
                            <var data-var="sell-amount-currency">
                                <span className="pl-1">{`${currFrom
                                    .split('/')
                                    .pop()
                                    .toUpperCase()} ${formatNumber(
                                    amountFrom,
                                )}`}</span>
                            </var>{' '}
                            at{' '}
                            <var data-var="platform-rate">
                                <span className={styles.bold}>
                                    {platformRate}
                                </span>
                            </var>
                        </Alert>
                    </Col>
                </Row>
                {error ? (
                    <Row>
                        <Col md={12}>
                            <Alert variant="danger" className="text-center">
                                <div>{error}</div>
                            </Alert>
                        </Col>
                    </Row>
                ) : null}
                <Row>
                    {timeLeft ? (
                        <div className={styles.timeLeft}>
                            Your offer is valid for{' '}
                            <var data-var="time-left-on-offer">
                                <b>{timeLeft}</b>
                            </var>
                        </div>
                    ) : null}
                </Row>
            </ModalBody>
            <Modal.Footer>
                <Button variant="light" onClick={onClose}>
                    Cancel
                </Button>
                <SubmitButton
                    isLoading={isLoading}
                    onClick={handleRefresh}
                    disabled={!isNeedRefresh}
                >
                    Refresh
                </SubmitButton>
                <SubmitButton
                    isLoading={isLoading}
                    onClick={handleConfirm}
                    disabled={isNeedRefresh}
                >
                    Confirm limit
                </SubmitButton>
            </Modal.Footer>
        </>
    );
};

export default LimitOrderConfirmationModal;
