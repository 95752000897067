import React, {useCallback, useEffect} from 'react';
import ReactDatePicker, {
    ReactDatePickerProps,
    registerLocale,
} from 'react-datepicker';
import {createPortal} from 'react-dom';
import {FormControl} from 'react-bootstrap';
import {parse, format, isBefore, isSameDay} from 'date-fns';
import en from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import it from 'date-fns/locale/it';
import fr from 'date-fns/locale/fr';
import cs from 'date-fns/locale/cs';
import pl from 'date-fns/locale/pl';

import {DISPLAY_DATE_FORMAT} from 'constants/date';
import {getLocalizeLanguage} from 'helpers/localize';
import {getNextPossibleDay} from 'helpers/dates';
import styles from './DatePicker.module.scss';

registerLocale('en', en);
registerLocale('de', de);
registerLocale('it', it);
registerLocale('fr', fr);
registerLocale('pl', pl);
registerLocale('cs', cs);

interface IProps extends Omit<ReactDatePickerProps, 'onChange' | 'selected'> {
    name?: string;
    value: string;
    dateFormat?: string;
    hasError?: boolean;
    setFieldValue?: (
        field: any,
        value: string,
        shouldValidate?: boolean,
    ) => void;
    onChange?: (value: string) => void;
    currencies?: string[];
    showMonthYearPicker?: boolean;
    considerValueDay?: never; // only used with HolidayDatePicker
}

const DatePicker = React.forwardRef<any, IProps>((props, ref) => {
    const {
        name,
        value,
        setFieldValue,
        onChange,
        dateFormat = DISPLAY_DATE_FORMAT,
        hasError = false,
        minDate,
        excludeDates,
        maxDate,
        disabled,
        showMonthYearPicker = false,
        isClearable,
        ...restProps
    } = props;
    const selected = value ? parse(value, dateFormat, new Date()) : null;
    const handleChange = useCallback(
        (date: Date | null) => {
            let pickerValue: string;

            if (date instanceof Date) {
                pickerValue = format(date, dateFormat);
            } else {
                pickerValue = '';
            }

            if (setFieldValue) setFieldValue(name, pickerValue);
            if (onChange) onChange(pickerValue);
        },
        [dateFormat, name, onChange, setFieldValue],
    );

    // initial can't be before minDate
    useEffect(() => {
        if (isBefore(selected, minDate) && !isSameDay(selected, minDate)) {
            handleChange(minDate);
        }
    }, [minDate, handleChange, dateFormat, selected]);

    // if excludedDays changes (e.g. on currency change), and current date is an excluded date,
    // get the next businessDay
    useEffect(() => {
        if (!excludeDates) {
            return;
        }

        if (excludeDates.some(d => isSameDay(d, selected))) {
            handleChange(getNextPossibleDay(selected, excludeDates));
        }
    }, [excludeDates, handleChange, selected]);

    return (
        <div className="d-block" data-notranslate>
            <ReactDatePicker
                {...restProps}
                minDate={minDate}
                maxDate={maxDate}
                excludeDates={excludeDates}
                locale={getLocalizeLanguage()}
                name={name}
                selected={selected}
                dateFormat={dateFormat}
                onChange={handleChange}
                customInput={
                    <FormControl className={hasError ? 'is-invalid' : ''} />
                }
                disabled={disabled}
                readOnly={disabled}
                ref={ref}
                showMonthYearPicker={showMonthYearPicker}
                showMonthDropdown
                showYearDropdown
                showWeekNumbers
                popperClassName={
                    showMonthYearPicker ? styles.onlyMonthAndYear : null
                }
                isClearable={isClearable}
                calendarClassName="react-datepicker-overwrite"
                popperContainer={({children}) =>
                    createPortal(
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                zIndex: 999999,
                            }}
                            data-notranslate
                        >
                            {children}
                        </div>,
                        document.body,
                    )
                }
            />
        </div>
    );
});

export default DatePicker;
