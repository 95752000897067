import React, {useMemo} from 'react';
import {Alert, Button, Col, Modal, Row, ProgressBar} from 'react-bootstrap';
import {ActiveDeal} from 'types/order';
import {DEAL_TIMEOUT, DEAL_TYPE_FORWARD} from 'constants/order';
import {formatDateInZurichTZ} from 'helpers/dates';
import SubmitButton from 'components/ui/Button/SubmitButton';
import CurrencyFlag from 'components/ui/CurrencyFlag/CurrencyFlag';
import {formatNumber} from 'helpers/common';
import classNames from 'classnames';
import useSimpleOrderConfirmationModal from 'components/modals/SimpleOrderConfirmationModal/useSimpleOrderConfirmationModal';
import ModalOverlay from 'components/ui/Overlays/ModalOverlay/ModalOverlay';
import TransactionConfirmationModal from 'components/modals/TransactionConfirmationModal/TransactionConfirmationModal';
import {useLocation} from 'react-router';
import styles from './SimpleOrderConfirmationModal.module.scss';

interface IProps {
    activeDeal: ActiveDeal;
    onClose: () => void;
    dealRequestParams: any;
}

const SimpleOrderConfirmationModal: React.FC<IProps> = ({
    activeDeal,
    onClose,
    dealRequestParams,
}) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const {
        activeBusinessPartner,
        timeLeft,
        handleRefresh,
        handleConfirm,
        openModalWithContent,
        closeModal,
        handleCancelDeal,
        error,
        isRefreshLoading,
        isLoadingConfirm,
        isLoadingCancel,
        refreshedDealRequest,
    } = useSimpleOrderConfirmationModal({activeDealRequest: activeDeal});

    const {
        platformRate,
        currencyFrom,
        amountFrom,
        currencyTo,
        amountTo,
        valueDate,
        dealType,
        spotRate,
        forwardPips,
        midRate,
    } = useMemo(() => {
        return refreshedDealRequest || activeDeal;
    }, [activeDeal, refreshedDealRequest]);

    const onConfirm = (confirmedDealRequest: ActiveDeal) => {
        const showPayToOwn = activeBusinessPartner?.own_contact?.bankAccounts
            ? activeBusinessPartner?.own_contact?.bankAccounts.find(
                  a =>
                      a.currency ===
                      `/web_api/currencies/${confirmedDealRequest.currencyTo}`,
              )
            : false;

        const contactId = activeBusinessPartner?.own_contact
            ? activeBusinessPartner.own_contact['@id'].split('/').pop()
            : undefined;
        closeModal('simpleOrder');
        openModalWithContent(
            'confirmation',
            <ModalOverlay doNotCloseOnOutsideClick modalKey="confirmation">
                <TransactionConfirmationModal
                    onConfirm={onClose}
                    activeDeal={confirmedDealRequest}
                    isFromPayment={params.has('amount')}
                    activeBpId={activeBusinessPartner['@id']}
                    showPayToOwn={showPayToOwn}
                    isEbillEnabled={
                        activeBusinessPartner.ebillSubscription?.enabled
                    }
                    contactId={contactId}
                />
            </ModalOverlay>,
        );
    };

    const getExchangeRate = () => {
        const actual = 1 / +platformRate;

        if (+platformRate <= 10) {
            return formatNumber(actual, 4);
        }
        if (+platformRate > 10 && +platformRate <= 100) {
            return formatNumber(actual, 5);
        }
        return formatNumber(actual, 6);
    };

    function renderAdditionalData() {
        return (
            <>
                <Col className={styles.colData} xs={6}>
                    <div>Spot</div>
                </Col>
                <Col className={styles.colData} xs={6}>
                    <div>{spotRate}</div>
                </Col>
                <Col className={styles.colData} xs={6}>
                    <div>Forward points</div>
                </Col>
                <Col className={styles.colData} xs={6}>
                    <div>{formatNumber(forwardPips, 4)}</div>
                </Col>
                <Col className={styles.colData} xs={6}>
                    <div>Exchange rate</div>
                </Col>
                <Col className={styles.colData} xs={6}>
                    <div>{formatNumber(platformRate, 4)}</div>
                </Col>
            </>
        );
    }

    return (
        <div className={styles.container}>
            <Modal.Header closeButton={false}>
                <Modal.Title as="h5">
                    Currency exchange (
                    {dealType === DEAL_TYPE_FORWARD ? 'Forward' : 'Spot'})
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {error ? (
                    <Row>
                        <Col md={12}>
                            <Alert variant="danger" className={styles.message}>
                                <div>{error}</div>
                            </Alert>
                        </Col>
                    </Row>
                ) : null}

                <Row>
                    <Col className={styles.colRate} md={12}>
                        <p>Your exchange rate</p>
                        <h4 data-notranslate className="mb-0">
                            {formatNumber(platformRate, 4)}
                        </h4>
                        <h6 data-notranslate className="mt-0 text-secondary">
                            {getExchangeRate()}
                        </h6>
                    </Col>
                    <Col className={styles.colPrice} md={6}>
                        <h4>You buy (credit)</h4>
                        <Alert
                            className={styles.price}
                            variant="dark"
                            data-notranslate
                        >
                            <CurrencyFlag code={currencyTo} />{' '}
                            {formatNumber(amountTo)}
                        </Alert>
                    </Col>
                    <Col className={styles.colPrice} md={6}>
                        <h4>You sell (debit)</h4>
                        <Alert
                            className={styles.price}
                            variant="dark"
                            data-notranslate
                        >
                            <CurrencyFlag code={currencyFrom} />{' '}
                            {formatNumber(amountFrom)}
                        </Alert>
                    </Col>
                    {dealType === DEAL_TYPE_FORWARD
                        ? renderAdditionalData()
                        : null}
                    <Col className={styles.colData} xs={6}>
                        <div>Value date</div>
                    </Col>
                    <Col className={styles.colData} xs={6} data-notranslate>
                        <div>{formatDateInZurichTZ(valueDate)}</div>
                    </Col>
                    {activeBusinessPartner.sandbox ? (
                        <>
                            <Col
                                className={classNames(
                                    styles.colData,
                                    styles.midRate,
                                )}
                                md={6}
                            >
                                <div>Mid rate</div>
                            </Col>
                            <Col
                                className={classNames(
                                    styles.colData,
                                    styles.midRate,
                                )}
                                md={6}
                                data-notranslate
                            >
                                <div>{formatNumber(midRate, 4)}</div>
                            </Col>
                        </>
                    ) : null}
                    <Col md={12}>
                        <div className={styles.timeLeftLabel}>
                            Your offer is valid for{' '}
                            <var data-var="timeLeft">
                                <span className="text-primary">{timeLeft}</span>
                            </var>
                            .
                        </div>
                        {/* Decrease time for 1 second for synchronization real time and progress bar width */}
                        {timeLeft ? (
                            <ProgressBar
                                className={styles.progress}
                                now={
                                    (100 / (DEAL_TIMEOUT - 1)) * (timeLeft - 1)
                                }
                            />
                        ) : null}
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="light"
                    onClick={() => handleCancelDeal(onClose)}
                >
                    Close
                </Button>
                <SubmitButton
                    onClick={() => handleRefresh(dealRequestParams)}
                    disabled={
                        isRefreshLoading ||
                        isLoadingCancel ||
                        isLoadingConfirm ||
                        !!timeLeft
                    }
                    isLoading={isRefreshLoading}
                >
                    Refresh
                </SubmitButton>
                <SubmitButton
                    onClick={() => handleConfirm(onConfirm)}
                    disabled={
                        isRefreshLoading ||
                        isLoadingCancel ||
                        isLoadingConfirm ||
                        !timeLeft
                    }
                    isLoading={isLoadingConfirm}
                >
                    Confirm
                </SubmitButton>
            </Modal.Footer>
        </div>
    );
};

export default SimpleOrderConfirmationModal;
